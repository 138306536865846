import React from "react";
import { connect } from "react-redux";
import { appActions, userActions } from "../_actions";
import { store } from "../_helpers";
import { confService, userService } from "../_services";
import "../css/style.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import i18next from "i18next";
import { Button, Collapse } from "@material-ui/core";
import TextFieldI18N from "../_components/TextFieldI18N";
import { user } from "../_reducers/user.reducer";

const LANG_LIST = ["ru", "en", "cn"];

const VoteItem = (props) => {
  const [isOpen, setOpen] = React.useState(true);

  return (
    <>
      <div></div>
      <Collapse></Collapse>
    </>
  );
};

class CreateVotePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "one",
      id: 0,
      link: 0,
      is_anonymous: 0,
      need_auth: 0,
      langList: ["ru"],
      allLang: {
        ru: i18next.t("lang_ru"),
        en: i18next.t("lang_en"),
        cn: i18next.t("lang_cn"),
      },
      title_i18n: {},
    };
  }

  clickLangItem = (item) => {
    let { langList } = this.state;
    if (langList.indexOf(item) >= 0) {
      if (item != "ru") langList = langList.filter((e) => e != item);
    } else {
      langList.push(item);
    }

    this.setState({ langList });
  };

  storeChange = () => {
    const { dispatch } = this.props;

    if (store.getState().user.isLoading === false) {
      if (store.getState().user.error === "") {
        //dispatch(appActions.setpage("conf_list"));
      }
    }
  };

  componentDidMount() {
    let id = 0;

    if (window.location.href.indexOf("newvote") < 0) {
      let urls = window.location.href.split("/");
      id = urls[urls.length - 1];
    }

    
    const link =  localStorage.getItem("navic_votelink");
    this.setState({ lang: this.props.app.lang, id, link});

    if (id>0) 
        this.getVoteInfo(id,link);
    
  }

  componentWillReceiveProps(nextProps) {
    const { selectedConf } = this.props.user;

    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
    }
  }

  setConfToState(confData) {
    let langList = confData.languages;

    if (!langList) langList = ["ru"];
  }

  getVoteInfo = (id, link) => {
    const self = this;
    const { dispatch } = this.props;

    if (id == 0) return;

    userService.getWrapper(
      "api/vote/main?signature=" + link + "&id=" + id,
      (data) => {
        if (data.status === 200) {
        }
      },
      (error) => {
        dispatch(userActions.set_error(i18next.t("cant_execute_request")));
      }
    );
  };

  onAdd = () => {};

  onSave = () => {};

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeI18N = (event, tabName) => {
    let tmpObj = this.state[event.target.name] ?? {};
    tmpObj = { ...tmpObj };
    tmpObj[tabName] = event.target.value;
    this.setState({ [event.target.name]: tmpObj });
  };

  saveData = () => {
    const { id, title_i18n, is_anonymous, need_auth } = this.state;

    let self = this;

    const postData = { header_i18n: title_i18n };

    let apiUrl = "api/vote?is_anonymous="+is_anonymous+"&need_auth="+need_auth;

    if (id > 0) apiUrl += "&id=" + id;

    userService.postWrapper(
      apiUrl,
      postData,
      (data) => {},
      (error) => {
        if (error != "") {
          self.props.dispatch(userActions.set_error(error));
        }
      }
    );
  };

  saveTitle = () => {
    this.saveData();
  };

  render() {
    const self = this;
    const { id, title_i18n, is_anonymous, need_auth } = this.state;

    return (
      <div className="layout__wrap">
        <h1>Создание голосования</h1>
        <TextFieldI18N
          autoFocus
          margin="dense"
          id="name"
          label={i18next.t("name")}
          fullWidth
          variant="standard"
          value={title_i18n}
          onChange={(e, tabName) => {
            let tmpI18N = title_i18n;
            tmpI18N[tabName] = e.target.value;

            self.setState({ title_i18n: tmpI18N, title: tmpI18N["ru"] });
          }}
          langList={LANG_LIST}
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                checked={is_anonymous ==1}
                onClick={() => self.setState({is_anonymous:1-is_anonymous})}
              />
            }
            label={i18next.t("is_anonymos")}
          />
            <FormControlLabel
            control={
              <Checkbox
                color="default"
                checked={need_auth ==1}
                onClick={() => self.setState({need_auth:1-need_auth})}
              />
            }
            label={i18next.t("need_auth")}
          />
        </FormGroup>

        <div className="layout__btn">
            <button className="btn" onClick={self.saveTitle}>
              {i18next.t("btn_save")}
            </button>
          </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedCreateVotePage = connect(mapStateToProps)(CreateVotePage);
export { connectedCreateVotePage as CreateVotePage };
