import React from "react";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { history } from "../_helpers";


const loginIsEmail = process.env.REACT_APP_LOGIN_IS_EMAIL;

export default class VoteListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      alertShow: false,
      errType: "error",
      snackText: "",
    };
  }

  componentDidMount() {}

  render() {
    const { lang } = this.state;

    return (
      <div style={{ width: "100%", height: "100%", fontSize:20, marginLeft:20 }}>
          <div style={{marginTop:10}}>
          <a 
            href="https://roscosmos.naviconf.com/votes/1?key=70cb51584e178ccda8d1648845c19271deecee7870324ee62fe2227a6448bdae">
            <span style={{"color":"blue"}}>
            Обратная связь по дню 1
            </span>
          </a>
          </div>
          <div style={{marginTop:10}}>
          <a href="https://roscosmos.naviconf.com/votes/2?key=2817b13be7e84d5a320aadebe6dc2b10f9e48afa8a327322b6bfdae0b4f121ef">
          <span style={{"color":"blue"}}>
            Обратная связь по дню 2
            </span>
          </a>
          </div>
          <div style={{marginTop:10,marginBottom:20}}>
          <a href="https://roscosmos.naviconf.com/votes/3?key=03029bc54bf5837bb068277f885d3e6fb98f1731b4b737a136cfcac1977f97f4">
          <span style={{"color":"blue"}}>
            Обратная связь по дню 3
            </span>
          </a>
          </div>
        <CustomizedButton
          title="Назад"
          prim
          className="form-button"
          onClick={() => history.push("/login")}
        />
      </div>
    );
  }
}
