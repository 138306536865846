import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextFieldI18N from "../_components/TextFieldI18N";
import TFJSONI18N from "../_components/TFJSON18N";

const LANG_LIST = ['ru','en','cn'];

export default function SettingsDialog(props) {
  const [name, setName] = React.useState("");
  const [name_i18n, setNameI18N] = React.useState({});
  const [description_i18n, setDescI18N] = React.useState({});
  const [description, setDesc] = React.useState({});
  const [count, setCount] = React.useState(0);
  
  const { handleClose, dialogOpen, dName, dNameI18N, dDesc, dDescI18N, text_cancel, text_save, text_section_name, placeholder_texts} = props;

  React.useEffect(() => {
    if (count === 0) {
      setName(dName);
      setNameI18N(dNameI18N);
      setDesc(dDesc);
      setDescI18N(dDescI18N);
    }
  },[count,dName,dDesc,dDescI18N,dNameI18N]);

  function saveData() {
    if (name !== "") {
      handleClose({ name, name_i18n, description, description_i18n });
      setCount(0);
    }
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
    
  }

  //<DialogTitle>{text_section_name}</DialogTitle>

  return (
    <Dialog open={dialogOpen}>
      <DialogContent>
        <TextFieldI18N
          autoFocus
          margin="dense"
          id="name"
          label="Имя"
          fullWidth
          variant="standard"
          value={name_i18n ?? {}}
          onChange={(e,tabName) => {
            let tmpObj = name_i18n;
            tmpObj[tabName] = e.target.value
            setNameI18N(tmpObj);
            setName(tmpObj['ru']);
            setCount(count + 1);
          }}
          langList={LANG_LIST}
        />
         <TFJSONI18N
          autoFocus
          margin="dense"
          id="name"
          label="Имя"
          fullWidth
          variant="standard"
          value={description_i18n ?? {}}
          onChange={(e,tabName, key) => {
            let tmpObj = description_i18n;
            let tmpSimple = description;
            tmpObj[tabName][key] = e.target.value;
            tmpSimple[key]= e.target.value;
            setDescI18N(tmpObj);
            setDesc(tmpSimple);
            setCount(count + 1);
          }}
          langList={LANG_LIST}
          placeholder_texts={placeholder_texts}
        />
        
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>{text_save}</Button>
        <Button onClick={cancelData}>{text_cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}
