import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TextField from "@mui/material/TextField";

function TFJSONI18N(props) {
  const {
    id,
    type,
    value,
    error,
    name,
    label,
    placeholder,
    className,
    autoFocus,
    inputProps,
    onChange,
    onLeave,
    size,
    disabled,
    langList,
    placeholder_texts
  } = props;

  const [tabIndex, setTabIndex] = React.useState(0);

  function handleChange(e, key) {
    onChange(e, langList[tabIndex], key);
  }

  
  const data = value[langList[tabIndex]];

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(e, val) => setTabIndex(val)}
            aria-label="lab API tabs example"
          >
            {langList.map((item, index) => (
              <Tab label={item} value={index} />
            ))}
          </TabList>
        </Box>
      </TabContext>
      {Object.keys(data).map((key) => (
        <TextField
          margin="dense"
          placeholder={label}
          id="desc"
          label={placeholder_texts[key]}
          fullWidth
          variant="standard"
          value={data[key]}
          onChange={(e) => handleChange(e, key)}
        />
      ))}
    </Box>
  );
}

export default TFJSONI18N;
