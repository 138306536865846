import { CircularProgress } from "@material-ui/core";
import React from "react";
import { cn as lang_cn } from "../_lang/cn.json";
import { en as lang_en } from "../_lang/en.json";
import { ru as lang_ru } from "../_lang/ru.json";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import Checkbox from "@mui/material/Checkbox";

import { connect } from "react-redux";
import { history, store } from "../_helpers";
import { confService } from "../_services";

import { userActions } from "../_actions";
import { Snack, TextFieldCell } from "../_components";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { agrUrl, serverUrl } from "../_constants";

import RegDialog from "./RegDialog";

import LeaderLogo from "../img/leader_id.png";
import vkLogo from "../img/vk.png";
import ruFlag from "../img/ru.png";
import enFlag from "../img/en.png";
import cnFlag from "../img/cn.png";

const loginIsEmail = process.env.REACT_APP_LOGIN_IS_EMAIL;

class RegistrPage extends React.Component {
  constructor(props) {
    super(props);
    // reset login status

    this.state = {
      username: "",
      pwd: "",
      pwd2: "",
      submitted: false,
      wrong: false,
      toShort: false,
      isStudent: true,
      agreeOk: false,
      slotInfoOpen: false,
      userType: "student",
      regDialogOpen: false,
    };

    store.subscribe(this.storeChange);
  }

  storeChange = (e) => {
    console.log(e);
    console.log(store.getState());
    if (
      store.getState().authentication.error &&
      store.getState().authentication.isLoading == false
    ) {
      console.log(store.getState().authentication.error);
      const snText = this.getI18N("user_with_this_email_already_exists");
      this.setState({ snackText: snText, alertShow: true, err_type: "error" });
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;

    if (process.env.REACT_APP_DISABLE_REG == true) {
      return;
    }

    let lang = localStorage.getItem("navic_lang") ?? "ru";
    this.setState({ lang });

    let nc_red = localStorage.getItem("navic_redirect");

    if (nc_red) localStorage.setItem("nc_usertype", "student");
    else {
      localStorage.setItem("nc_usertype", "");
      this.setState({ userType: "" });
    }
  }

  getI18N = (textKey) => {
    const { lang } = this.state;

    if (!lang) return;

    if (lang == "ru") {
      if (Object.keys(lang_ru.translation).indexOf(textKey) >= 0)
        return lang_ru.translation[textKey];
    }

    if (lang == "en")
      if (Object.keys(lang_en.translation).indexOf(textKey) >= 0)
        return lang_en.translation[textKey];

    if (lang == "cn")
      if (Object.keys(lang_cn.translation).indexOf(textKey) >= 0)
        return lang_cn.translation[textKey];

    return textKey;
  };

  onCloseSnack = () => {
    this.setState({ alertShow: false });
  };

  clickAgr = () => {
    // this.setState({slotInfoOpen:true});
    window.open(agrUrl);
  };

  clickLic = () => {
    let urlLic = "https://naviconf.com/docs/eula/";
    window.open(urlLic);
  };

  regOpenAI = (userType, regType) => {
    if (!regType) regType = this.state.regType;

    this.setState({ regDialogOpen: false, userType });

    if (!userType) return;

    if (regType != "main") {
      localStorage.setItem("nc_usertype", userType);
      let url = serverUrl + "auth/" + regType + "?type=" + userType;

      window.open(url);
    } else {
      this.handleSubmit(userType);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  openRegDialog = (val) => {
    const { userType } = this.state;

    if (!userType) this.setState({ regDialogOpen: true, regType: val });
    else {
      this.setState({ regType: val });
      this.regOpenAI(userType, val);
    }
  };

  handleSubmit = (userType) => {
    const self = this;

    const { username, pwd, pwd2, isStudent, agreeOk } = this.state;
    const { dispatch } = this.props;

    let snText = "";

    if (agreeOk == false) {
      snText = this.getI18N("pers_data_agr");
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (!loginIsEmail && username.length < 3) {
      snText = this.getI18N("login_3_char");
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (loginIsEmail && username.length < 3) {
      snText = this.getI18N("too_short_email");
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (!loginIsEmail && confService.validateLogin(username) == false) {
      snText = this.getI18N("enter_correct_login");
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (loginIsEmail && confService.validateEmail(username) == false) {
      snText = this.getI18N("enter_correct_email");
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (pwd.length < 6) {
      snText = this.getI18N("the_password_must_be_6_characters");
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (confService.validatePassword(pwd) == false) {
      snText = this.getI18N("password_regexp");
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (pwd != pwd2) {
      snText = this.getI18N("pwd_not_same");
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (userType == "") {
      snText = this.getI18N("select_acc_type");
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else {
      dispatch(
        userActions.registr({
          username,
          password: pwd,
          isStudent: userType == "student",
        })
      );
    }
  };

  changeLang = () => {
    let { lang } = this.state;
    if (lang == "ru") lang = "en";
    else if (lang == "en") lang = "cn";
    else lang = "ru";

    localStorage.setItem("navic_lang", lang);
    this.setState({ lang });
  };

  render() {
    console.log(this.props);

    const { error, isLoading } = this.props;

    const {
      slotInfoOpen,
      username,
      submitted,
      pwd,
      pwd2,
      isStudent,
      agreeOk,
      snackText,
      err_type,
      alertShow,
      userType,
      lang,
      showPassword1,
      showPassword2,
    } = this.state;

    if (process.env.REACT_APP_DISABLE_REG == "true")
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          Disabled for this version
        </div>
      );

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div style={{ position: "absolute", top: 20, left: 20, fontSize: 20 }}>
          <div className="lang">
            <button className="lang__btn" onClick={this.changeLang}>
              {lang == "ru" && (
                <img src={ruFlag} alt="" className="lang__flag" />
              )}
              {lang == "en" && (
                <img src={enFlag} alt="" className="lang__flag" />
              )}
              {lang == "cn" && (
                <img src={cnFlag} alt="" className="lang__flag" />
              )}
              <span style={{ fontSize: 16 }}>{lang}</span>
            </button>
          </div>
        </div>

        <div style={{ paddingLeft: 30, paddingRight: 30 }}>
          <form
            name="form"
            onSubmit={this.handleSubmit}
            style={{ justifyContent: "center" }}
          >
            <div style={{ verticalAlign: "center" }} />
            <div className={submitted && !username ? " has-error" : ""}>
              <TextFieldCell
                id="login"
                label={
                  loginIsEmail
                    ? this.getI18N("user_email")
                    : this.getI18N("user_login")
                }
                name="username"
                type="text"
                value={username}
                onChange={this.handleChange}
                autoFocus
              />
            </div>
            <div className={submitted && !username ? " has-error" : ""}>
              <TextField
                style={{ display: "flex", marginBottom: 20 }}
                id="pwd"
                label={this.getI18N("password")}
                name="pwd"
                type={showPassword1 ? "text" : "password"}
                value={pwd}
                onChange={this.handleChange}
                InputProps={{
                  style: { fontSize: 24, fontWeight: "bold", color: "#0b1218" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({ showPassword1: !showPassword1 })
                        }
                        edge="end"
                      >
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={submitted && !username ? " has-error" : ""}>
              <TextField
                style={{ display: "flex", marginBottom: 20 }}
                id="pwd"
                label={this.getI18N("password")}
                name="pwd2"
                type={showPassword2 ? "text" : "password"}
                value={pwd2}
                onChange={this.handleChange}
                InputProps={{
                  style: { fontSize: 24, fontWeight: "bold", color: "#0b1218" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({ showPassword2: !showPassword2 })
                        }
                        edge="end"
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
                fontSize: 20,
                marginLeft: -3,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Checkbox
                color="default"
                style={{ paddingLeft: 0, paddingTop: 0 }}
                checked={agreeOk}
                onClick={() => this.setState({ agreeOk: !agreeOk })}
              />
              <p>
                {this.getI18N("pd_agr_1")}{" "}
                <span
                  style={{ color: "blue", textDecoration: "underline" }}
                  onClick={this.clickAgr}
                >
                  {this.getI18N("pd_agr_2")}
                </span>{" "}
                {this.getI18N("pd_agr_3")}{" "}
                <span
                  style={{ color: "blue", textDecoration: "underline" }}
                  onClick={this.clickLic}
                >
                  {this.getI18N("lic_agr")}
                </span>
              </p>
            </div>
            <div
              className="form-group"
              style={{
                marginTop: 5,
                justifyContent: "space-between",
                flex: 1,
                display: "flex",
              }}
            >
              <div
                className="form-group"
                style={{
                  flex: 1,
                  display: "flex",
                }}
              >
                <CustomizedButton
                  style={{ marginRight: 5 }}
                  onClick={() => this.openRegDialog("main")}
                  id="category-button-add"
                  type="submit"
                  title={this.getI18N("registration")}
                  prim
                />
              </div>
              <a
                onClick={() => history.push("/login")}
                style={{ marginTop: 10, fontSize: 20 }}
              >
                <span style={{ color: "blue" }}>
                  {this.getI18N("btn_cancel")}
                </span>
              </a>
            </div>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div onClick={() => this.openRegDialog("vkontakte")}>
                <img src={vkLogo} />
              </div>
              <div
                onClick={() => this.openRegDialog("leader-id")}
                style={{ marginLeft: 40 }}
              >
                <img src={LeaderLogo} />
              </div>
            </div>
          </form>
        </div>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}

        <RegDialog
          texts={{
            org: this.getI18N("type_org"),
            visitor: this.getI18N("type_visitor"),
            enter: this.getI18N("login_enter"),
            cancel: this.getI18N("btn_cancel"),
            usertype: this.getI18N("select_user_type"),
          }}
          handleClose={this.regOpenAI}
          dialogOpen={this.state.regDialogOpen}
        />

        <Snack
          open={alertShow}
          variant={err_type}
          text={snackText}
          onClose={this.onCloseSnack}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, error, isLoading } = state.authentication;
  return {
    loggingIn,
    isLoading,
    error,
  };
}

const connectedRegistrPage = connect(mapStateToProps)(RegistrPage);
export { connectedRegistrPage as RegistrPage };
