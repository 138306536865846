import React from "react";
import i18next from "i18next";
import { withStyles } from "@material-ui/core/styles";
import "../css/style.css";
import "../css/constructor.css";

import { connect } from "react-redux";
import { userActions } from "../_actions";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { DragDropContext } from "react-beautiful-dnd";
import { CircularProgress } from "@material-ui/core";
import { ConferenceNotSelected } from "../_components/ConferenceNotSelected";
import RightIcon from "@material-ui/icons/ChevronRight";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import settingsImg from "../img/settings.png";
import EditDialog from "../_components/EditDialog";
import AddDialog from "../_components/AddDialog";
import SettingsDialog from "./SettingsDialog";
import SectionDialog from "./SectionDialog";
import ModeratorsDialog from "./ModeratorsDialog";
import MoveDialog from "./MoveDialog";
import SlotItem from "./SlotItem";
import SectionItem from "./SectionItem";
import SlotPlaceholder from "./SlotPlaceholder";
import { scheduleService, confService, userService } from "../_services";
import DragMenu from "./DragMenu";
import addLogo from "../img/add.png";
import { DiscFull } from "@material-ui/icons";

export const tmpDesc = { description: "", room: "", time: "", url: "" };
export const tmpDescI18N = {
  ru: { description: "", room: "", time: "", url: "" },
  en: { description: "", room: "", time: "", url: "" },
  cn: { description: "", room: "", time: "", url: "" },
};

const add_textes = {
  theme: "add_theme",
  speaker: "add_speaker",
  room: "add_room",
};
const edit_textes = {
  theme: "edit_theme",
  speaker: "edit_speaker",
  room: "edit_room",
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});

function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join("-");
}

function newAroundDays(cDate, minDate, maxDate) {
  const minDateF = new Date(minDate);
  const maxDateF = new Date(maxDate);
  let curDateF = new Date(cDate);

  const aDays = [];
  let minVal = -1;
  let maxVal = 1;
  let oldDateF = new Date(cDate);

  if (formatDate(curDateF) === formatDate(minDateF)) {
    minVal = 0;
    maxVal = 2;
    oldDateF = minDateF;
  }

  if (formatDate(curDateF) === formatDate(maxDateF)) {
    minVal = -2;
    maxVal = 0;
    oldDateF = maxDateF;
  }

  for (let i = minVal; i < maxVal + 1; i++) {
    curDateF = new Date(oldDateF);
    if (i != 0) {
      curDateF.setDate(oldDateF.getDate() + i);
    }

    const fd = formatDate(curDateF);
    if (curDateF >= minDateF && curDateF <= maxDateF) {
      aDays.push(fd);
    }
  }

  return aDays;
}

class TimeTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragMenuOpen: true,
      themesCounter: -1,
      speakersCounter: -200,
      roomsCounter: -300,
      themeDialogOpen: false,
      editThemeId: -1,
      selectedDate: null,
      aroundDates: ["2022-05-29", "2022-05-30", "2022-05-31"],
      sectionInd: -1,
      sectionName: "",
      sectionNameI18N: {},
      sectionDesc: tmpDesc,
      sectionDescI18N: tmpDescI18N,
      sectionDialogOpen: false,
      minDate: "2020-01-01",
      maxDate: "2030-12-31",
      fileData: null,
      showDeclined: true,
      editThemeVal: "",
      showEditTheme: false,
      error: "",
      curDay: "0",
      editType: "",
      addType: "",
      oldTheme: "",
      fileStatus: 0,
      isSlotService: false,
      dialogLabel: i18next.t("dialog_name"),
      moderators: [],
    };
  }

  componentDidMount() {
    this.setState({ lang: this.props.app.lang });

    this.getAllData();
  }

  componentWillReceiveProps(nextProps) {
    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      this.getAllData();
    }
  }

  getAllData = () => {
    if (this.props.conf_data && this.props.conf_data.type) {
      this.setState({ format: this.props.conf_data.type });
    } else {
      this.setState({ format: "hybrid" });
    }

    if (
      this.props.conf_id > 0 &&
      this.props.conf_data &&
      this.props.conf_data.start_time &&
      this.props.conf_data.end_time
    ) {
      this.getDates(
        this.props.conf_data.start_time,
        this.props.conf_data.end_time
      );
    }
  };

  clickDay = (day) => {
    this.changeDate(day);
  };

  dateShift = (day_shift) => {
    const { selectedDate, minDate, maxDate } = this.state;

    const minDateF = new Date(minDate);
    const maxDateF = new Date(maxDate);
    const curDateF = new Date(selectedDate);
    let newDate = "";

    if (day_shift < 0 && curDateF > minDateF) {
      curDateF.setDate(curDateF.getDate() + day_shift);
      newDate = formatDate(curDateF);
      this.changeDate(newDate);
    }

    if (day_shift > 0 && curDateF < maxDateF) {
      curDateF.setDate(curDateF.getDate() + day_shift);
      newDate = formatDate(curDateF);
      this.changeDate(newDate);
    }
  };

  changeDate = (e) => {
    const cDate = e;
    const { minDate, maxDate } = this.state;

    this.setState({ curDate: cDate });
    const aDays = newAroundDays(cDate, minDate, maxDate);
    this.setState({ selectedDate: cDate, aroundDates: aDays });
    scheduleService.getSchedule(this.props.conf_id, cDate);
  };

  fileUpload = (selectedFile) => {
    // this.setState({themeFormData:formdata});

    const data_upload = new FormData();
    data_upload.append("path", selectedFile);
    data_upload.append("type", "presentation");

    this.setState({ fileStatus: 1, fileData: data_upload });
  };

  getDates = (startDate, endDate) => {
    const startD = startDate.substr(0, 10);
    const endD = endDate.substr(0, 10);

    const aDays = newAroundDays(startD, startD, endD);
    this.setState({
      selectedDate: startD,
      minDate: startD,
      maxDate: endD,
      aroundDates: aDays,
    });

    if (this.props.conf_id > 0) {
      const c_id = this.props.conf_id;
      this.setState({ curDate: startD });

      this.getSpeakerList(c_id);
      this.getRoomList(c_id);
      this.getSchedule(c_id, startD);
    }
  };

  dragMenuClick = () => {
    this.setState({ dragMenuOpen: !this.state.dragMenuOpen });
  };

  deleteThemeBeforeSchedule = (id, th_name, post_data, type = 0) => {
    if (th_name) {
      post_data.push({ name: th_name });
    }

    this.delThemeFromList(id, [...post_data]);
  };

  updateSchedule = (post_data, is_reload = false) => {
    const { selectedDate } = this.state;

    scheduleService.updateSchedule(
      this.props.conf_id,
      post_data,
      selectedDate,
      is_reload
    );
  };

  handleStart = (e, i) => {
    this.setState({ isDragging: true });
  };

  handleStop = (e, i) => {
    const self = this;

    const {
      sections,
      rooms,
      themes,
      service_themes,
      speakers,
      slots,
      selectedDate,
    } = this.props.schedule;

    this.setState({ isDragging: false });

    let post_data = [];

    if (e.destination && e.destination.droppableId) {
      let drag_type = "";

      let dragId = -1;

      const tmp_drag = e.draggableId.split("_");
      if (tmp_drag.length == 2) {
        drag_type = tmp_drag[0];
        dragId = parseInt(tmp_drag[1]);
      }

      const drop_data = e.destination.droppableId.split("_");

      if (drop_data.length < 3) {
        return 0;
      }

      const drop_name = drop_data[0];
      const section_id = drop_data[1];
      const drop_id = drop_data[2];
      let sp_id = -1;
      if (drop_name == "speaker") {
        sp_id = drop_data[3];
      }

      let slot_data = null;

      if (section_id >= 0) {
        slot_data = { ...sections[section_id].slots[drop_id] };
      } else {
        slot_data = { ...slots[drop_id] };
      }

      let drag_name = [];

      if (drop_name === "theme" && drag_type === "theme") {
        drag_name = themes.filter((e) => e.id == dragId);
      }

      if (drop_name === "theme" && drag_type === "servicetheme") {
        drag_name = service_themes.filter((e) => e.id == dragId);
      }

      if (drop_name === "speaker") {
        drag_name = speakers.filter((e) => e.id == dragId);
      }

      if (drop_name === "room") {
        drag_name = rooms.filter((e) => e.id == dragId);
      }

      if (
        drag_type == "theme" &&
        drop_name == "theme" &&
        drag_name.length > 0
      ) {
        const { theme } = slot_data;
        const { id } = slot_data;
        const new_data = [
          {
            id: id,
            entity_type: "slot",
            slot_entity: {
              entity_id: drag_name[0].id,
              name: drag_name[0].name,
              entity_type: "lesson",
              type: this.state.format,
            },
          },
        ];

        this.updateSchedule(new_data);

        //this.deleteThemeBeforeSchedule(drag_name[0].id, theme, new_data);
      }

      if (
        drag_type == "servicetheme" &&
        drop_name == "theme" &&
        drag_name.length > 0
      ) {
        const { theme } = slot_data;
        const { id } = slot_data;

        const new_data = [
          {
            id: id,
            entity_type: "slot",
            slot_entity: {
              entity_id: drag_name[0].id,
              name: drag_name[0].name,
              entity_type: "service_lesson",
              type: this.state.format,
            },
          },
        ];

        this.updateSchedule(new_data);
      }

      if (
        drag_type == "speaker" &&
        drop_name == "speaker" &&
        drag_name.length > 0
      ) {
        const sp_list = [...slot_data.speakers];

        let is_new = true;

        for (let k = 0; k < sp_list.length; k++) {
          if (sp_list[k].name == drag_name[0].name) {
            is_new = false;
            break;
          }
        }

        if (is_new) {
          const spOld = { ...slot_data.speakers[sp_id] };
          const { id } = slot_data;

          if ((spOld && dragId != spOld.id) || !spOld) {
            if (id > 0) {
              post_data = [{ id, speakers: [{ id: dragId }] }];
            }

            if (spOld && spOld.id && spOld.id > 0) {
              post_data[0].speakers.push({ id: spOld.id, action: "unlink" });
            }
          }
        } else {
          alert(i18next.t("speaker_already_exists"));
        }
      }

      if (
        drag_type === "room" &&
        drop_name === "room" &&
        drag_name.length > 0
      ) {
        const room_id = drag_name[0].id;

        const { id } = slot_data;

        if (id > 0) {
          post_data = [
            { id, room: { id: room_id, number: drag_name[0].number } },
          ];
        }
      }
      if (post_data.length > 0) {
        self.updateSchedule(post_data);
      }
    }
  };

  editThemeFromList = (item) => {
    console.log(item);

    if (!item) return;

    let lessons = this.props.schedule.themes ?? [];

    let id = item.id;

    if (item.entity && item.entity.id) id = item.entity.id;

    let lessonIndex = lessons.map((e) => e.id).indexOf(id);

    if (lessonIndex < 0) return;

    let lesson = lessons[lessonIndex];

    console.log("LL", lesson);

    if (!lesson.name_i18n) {
      item.name_i18n = { ru: item.name, en: "", cn: "" };
    } else {
      item.name_i18n = lesson.name_i18n;
    }

    if (lesson) {
      item.name = lesson.name;
      item.description_i18n = lesson.description_i18n;
    }

    if (lessons.description) {
      if (!lesson.description_i18n) {
        item.entity.description_i18n = {
          ru: item.description,
          en: "",
          cn: "",
        };
      } else {
        item.description_i18n = lesson.description_i18n;
      }
    }

    console.log("ITM", item);

    this.setState({
      editVal: item,
      editId: item.slot_type != "lesson" ? item.id : item.entity.id,
      editDialog: true,
      editType: "theme",
      dialogLabel: i18next.t("name"),
      fileData: null,
      fileStatus: 0,
    });
  };

  delThemeFromList = (id, post_data = null) => {
    const c_id = this.props.conf_id;
    scheduleService.delTheme(c_id, id, post_data, true);
  };

  openEditServiceTheme = (id, val) => {
    const { service_themes } = this.props.schedule;
    let thObj = null;

    for (let i = 0; i < service_themes.length; i++) {
      if (service_themes[i].id == id) {
        thObj = { ...service_themes[i] };
        break;
      }
    }

    if (!thObj) return;

    this.setState({
      editVal: thObj,
      editId: id,
      editDialog: true,
      editType: "theme",
      fileData: null,
      fileStatus: 0,
      isSlotService: true,
      isEditSlotOnly: true,
    });
  };

  editServiceTheme = (id, val) => {
    const { editVal, isEditSlotOnly, selectedDate } = this.state;

    if (editVal && editVal.slot_type && editVal.slot_type == "service_lesson") {
      const post_data = [
        { id: editVal.id, description: val.description, entity_type: "slot" },
      ];
      this.updateSchedule(post_data);
    } else if (isEditSlotOnly) {
      scheduleService.editServiceTheme(
        this.props.conf_id,
        selectedDate,
        editVal.id,
        val.name
      );
    }

    this.setState({
      editVal: null,
      editId: id,
      editDialog: true,
      editType: "theme",
      isSlotService: true,
      isEditSlotOnly: false,
    });
  };

  delServiceTheme = (id) => {
    scheduleService.delServiceTheme(id);
  };

  editSpeakerFromList = (id) => {
    const { speakers } = this.props.schedule;
    let spObj = null;

    for (let i = 0; i < speakers.length; i++) {
      if (speakers[i].id == id) {
        spObj = { ...speakers[i] };

        if (!spObj.name_i18n)
          spObj.name_i18n = { ru: spObj.name, en: "", cn: "" };

        if (!spObj.description_i18n && spObj.description)
          spObj.description_i18n = { ru: spObj.description, en: "", cn: "" };

        if (!spObj.city_i18n && spObj.city)
          spObj.city_i18n = { ru: spObj.city, en: "", cn: "" };

        if (!spObj.comment_i18n && spObj.comment)
          spObj.comment_i18n = { ru: spObj.comment, en: "", cn: "" };

        break;
      }
    }

    this.setState({
      editVal: spObj,
      editId: id,
      editDialog: true,
      editType: "speaker",
      dialogLabel: i18next.t("dialog_name"),
    });
  };

  delSpeakerFromList = (id) => {
    scheduleService.delSpeaker(this.props.conf_id, id);
  };

  editRoomFromList = (id) => {
    const { rooms } = this.props.schedule;
    let roomVal = "";
    let roomObj = null;

    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i].id == id) {
        console.log(rooms[i]);

        roomObj = { name: rooms[i].number };

        if (!rooms[i].number_i18n) {
          roomObj.name_i18n = { ru: roomObj.name, en: "", cn: "" };
        } else roomObj.name_i18n = rooms[i].number_i18n;

        roomObj = { ...roomObj, ...rooms[i] };

        break;
      }
    }

    console.log(roomObj);

    this.setState({
      editVal: roomObj,
      editId: id,
      editDialog: true,
      editType: "room",
      dialogLabel: i18next.t("name"),
    });
  };

  delRoomFromList = (id) => {
    const c_id = this.props.conf_id;
    scheduleService.delRoom(c_id, id);
  };

  addRoomDialog = () => {
    this.setState({
      addDialog: true,
      addType: "room",
      dialogLabel: i18next.t("name"),
    });
  };

  addSpeakerDialog = () => {
    this.setState({
      addDialog: true,
      addType: "speaker",
      dialogLabel: i18next.t("dialog_name"),
    });
  };

  addThemeDialog = () => {
    this.setState({
      addDialog: true,
      addType: "theme",
      fileData: null,
      dialogLabel: i18next.t("name"),
      fileStatus: 0,
    });
  };

  addDialog = () => {
    this.setState({ dialogOpen: true });
  };

  closeDialog = (data) => {
    this.setState({ addDialog: false });

    if (!data) {
      return;
    }

    const val = data.name;

    const { themes, rooms, speakers, sections } = this.props.schedule;
    const { addType, fileData } = this.state;

    console.log(data, addType);

    let isExists = false;
    const self = this;

    if (addType == "speaker" && val) {
      for (let i = 0; i < speakers.length; i++) {
        if (speakers[i].name == val) {
          isExists = true;
          break;
        }
      }
    }

    if (addType == "theme" && !data.isServiceSlot) {
      for (let i = 0; i < themes.length; i++) {
        if (themes[i].name == val) {
          isExists = true;
          break;
        }
      }
    }

    if (addType == "room" && val) {
      for (let i = 0; i < rooms.length; i++) {
        if (rooms[i].number == val) {
          isExists = true;
          break;
        }
      }
    }

    if (val && isExists == false && addType == "theme" && !data.isServiceSlot) {
      scheduleService.addTheme(this.props.conf_id, { ...data }, fileData);
    }

    if (val && isExists == false && addType == "theme" && data.isServiceSlot) {
      const fd = new FormData();
      fd.append("name_i18n", JSON.stringify(data.name_i18n));

      console.log(fd);

      scheduleService.addServiceTheme(val, fd);
    }

    if (val && isExists == false && addType == "room") {
      const json_data = {
        conference_id: this.props.conf_id,
        number: val,
        number_i18n: data.name_i18n,
        seats: data.seats,
        description: data.description,
        description_i18n: data.description_i18n,
        building_id: this.props.building_id,
      };

      scheduleService.addRoom(this.props.conf_id, json_data);
    }

    if (val && isExists == false && addType == "speaker") {
      const json_data = { name: val };

      scheduleService.addSpeaker(this.props.conf_id, json_data);
    }
  };

  closeSettingsDialog = (val) => {
    if (val) {
      this.setState({
        showSettingsDialog: false,
        showDeclined: val.showDeclined,
      });
    } else {
      this.setState({ showSettingsDialog: false });
    }
  };

  closeEditDialog = (val) => {
    console.log(val);

    if (val && val.name) {
      this.closeSaveDialog(val);
    } else {
      this.setState({
        editId: -1,
        editDialog: false,
        editVal: null,
        editType: "",
        themeFormData: null,
        isSlotService: false,
      });
    }
  };

  closeSaveDialog = (data) => {
    const {
      fileData,
      editId,
      editVal,
      editType,
      isSlotService,
      isEditSlotOnly,
      curDate,
    } = this.state;

    const self = this;
    const val = data.name;

    if (val && editType == "speaker") {
      self.setState({ editId: -1, editDialog: false, editVal: "" });
      scheduleService.editSpeaker(self.props.conf_id, editId, { ...data });
    }

    if (val && editType == "theme") {
      if (isEditSlotOnly) {
        const fd = new FormData();
        fd.append("name_i18n", data.name_i18n);

        self.editServiceTheme(editId, data.name, fd);
      } else if (editVal.slot_type == "service_lesson") {
        scheduleService.editSlotDesc(
          self.props.conf_id,
          curDate,
          editId,
          data.description
        );

        //self.editServiceTheme(editId, data);
        //const post_all = [{ id: editVal.id, description:data.description, entity_type: "slot"}];
        //this.updateSchedule(post_all);
      } else {
        if (!data.description) delete data.description;

        if (!data.description_i18n) delete data.description_i18n;

        if (data.description_i18n && !data.description) {
          data.description = data.description_i18n["ru"];
        }

        console.log("DD", data);

        scheduleService.editTheme(
          self.props.conf_id,
          editId,
          { ...data },
          fileData
        );
      }

      self.setState({
        editId: -1,
        editDialog: false,
        editVal: "",
        isSlotService: false,
      });
    }

    if (val && editType == "room") {
      self.setState({ editId: -1, editDialog: false, editVal: "" });

      const json_data = {
        conference_id: this.props.conf_id,
        number: val,
        number_i18n: data.name_i18n,
        seats: data.seats,
        description: data.description,
        description_i18n: data.description_i18n,
        building_id: this.props.building_id,
      };

      console.log("EDIT", json_data);

      scheduleService.editRoom(editId, json_data, this.props.conf_id);
    }
  };

  changeSectionName = (e, index) => {
    console.log("EEE", e);
    scheduleService.changeSectionName(this.props.conf_id, e, index);
  };

  unbindSlot = (sectIndex, slotIndex) => {
    const { sections, themes, slots } = this.props.schedule;
    const tId = sections[sectIndex].slots[slotIndex].id;
    const post_all = [
      { id: tId, entity_type: "slot", section: { action: "unlink" } },
    ];
    this.updateSchedule(post_all);
  };

  delSlot = (sectIndex, slotIndex) => {
    const { sections, themes, slots } = this.props.schedule;
    const theme = null;
    let tId = null;
    let sType = null;

    if (sectIndex >= 0) {
      tId = sections[sectIndex].slots[slotIndex].id;
      sType = sections[sectIndex].slots[slotIndex].slot_type;
    } else {
      tId = slots[slotIndex].id;
      sType = slots[slotIndex].slot_type;
    }

    if (tId) {
      const post_all = [{ id: tId, action: "delete" }];

      if (sType !== undefined) post_all[0].entity_type = "slot";

      this.updateSchedule(post_all);
    } else {
    }
  };

  moveSect = (sections) => {
    const { conf_id } = this.props;
    const post_data = [];
    const self = this;

    let sectLength = sections.length;

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].slots && sections[i].slots.length > 0) {
        const l_id = sections[i].slots[0].id;
        post_data.push({
          id: l_id,
          entity_type: "slot",
          section: { id: sections[i].id, order_id: sectLength - i },
        });
      }
    }

    this.setState({ sections: [] });

    this.updateSchedule(post_data);
  };

  moveSlotOrder = (data) => {
    const { conf_id } = this.props;
    const post_data = [];
    const self = this;

    console.log(data);
    this.setState({ sections: [] });

    this.updateSchedule(data);
  };

  setSortType = (id, val) => {
    const c_id = this.props.conf_id;

    scheduleService.changeSectionOrderType(c_id, id, val);
  };

  sectUp = (sectIndex) => {
    const { sections } = this.props.schedule;

    if (sectIndex > 0) {
      const tmpElem = { ...sections[sectIndex] };
      sections.splice(sectIndex, 1);
      sections.splice(sectIndex - 1, 0, tmpElem);
      this.moveSect(sections);
    }
  };

  sectDown = (sectIndex) => {
    const { sections } = this.props.schedule;

    if (sectIndex < sections.length - 1) {
      const tmpElem = { ...sections[sectIndex] };
      sections.splice(sectIndex, 1);
      sections.splice(sectIndex + 1, 0, tmpElem);
      this.moveSect(sections);
    }
  };

  editModerators = (index) => {
    const { sections } = this.props.schedule;

    const { speakers } = sections[index];

    console.log(speakers);

    let speakers_new = [...speakers];

    if (!speakers_new || speakers_new.length == 0)
      speakers_new.push({ name: "", status: "0" });

    this.setState({
      moderatorsDialogOpen: true,
      sectionInd: index,
      moderators: speakers_new,
    });
  };

  getThemeList = (id) => {
    scheduleService.getThemeList(id);
  };

  getServiceThemeList = (id) => {
    scheduleService.getServiceThemeList();
  };

  getSpeakerList = (id) => {
    scheduleService.getSpeakerList();
  };

  getRoomList = (id) => {
    scheduleService.getRoomList(id);
  };

  getSchedule = (id, sd) => {
    const self = this;
    const { selectedDate } = this.state;

    if (!sd) {
      sd = selectedDate;
    }

    scheduleService.getSchedule(
      id,
      sd,
      (data) => {},
      (error) => {}
    );
  };

  addThemeToList = (val) => {
    const c_id = this.props.conf_id;
    scheduleService.addTheme(c_id, val);
  };

  addTheme = (index) => {
    const { selectedDate, format } = this.state;
    const { sections } = this.props.schedule;

    if (!sections[index].slots) {
      sections[index].slots = [];
    }

    const sectId = sections[index].id;
    const post_data = [
      { name: "", section: { id: sectId }, date: selectedDate },
    ];

    post_data[0].type = format;

    this.updateSchedule(post_data, true);
  };

  delSpeaker = (index, slotIndex, sectionIndex) => {
    const { sections } = this.props.schedule;
    const spId = sections[sectionIndex].slots[slotIndex].speakers[index].id;
    sections[sectionIndex].slots[slotIndex].speakers = sections[
      sectionIndex
    ].slots[slotIndex].speakers.filter((e, i) => index != i);
    if (sections[sectionIndex].slots[slotIndex].speakers.length == 0) {
      sections[sectionIndex].slots[slotIndex].speakers = [
        { name: "", status: "0", payment_status: "0" },
      ];
    }

    if (spId) {
      const lessonId = sections[sectionIndex].slots[slotIndex].id;
      const post_data = [
        { id: lessonId, speakers: [{ action: "unlink", id: spId }] },
      ];
      this.updateSchedule(post_data);
    } else {
      // this.setState({sections:sections});
    }
  };

  changeSpeakerPartip = (e, sectionIndex, slotIndex, index) => {
    const { sections, slots } = this.props.schedule;

    if (e != "1") {
      let id = null;
      let slot_data = null;

      if (sectionIndex >= 0) {
        slot_data = { ...sections[sectionIndex].slots[slotIndex] };
      } else {
        slot_data = { ...slots[slotIndex] };
      }

      id = slot_data.entity.speakers[index].id;
      const post_data = [
        {
          id: slot_data.id,
          entity_type: "slot",
          slot_entity: {
            entity_id: slot_data.entity.id,
            entity_type: "lesson",
          },
          speakers: [{ id, status: e }],
        },
      ];
      this.updateSchedule(post_data);
    }
  };

  addSpeaker = (index, sectionIndex) => {
    const { sections, slots } = this.props.schedule;

    if (sectionIndex >= 0) {
      sections[sectionIndex].slots[index].entity.speakers.push({
        name: "",
        status: "0",
      });
    } else {
      slots[index].entity.speakers.push({ name: "", status: "0" });
    }
    scheduleService.setSlots([...slots]);
  };

  deleteSectionDialog = (ind) => {
    const res_dialog = window.confirm(i18next.t("del_section") + "?");
    if (res_dialog == true) {
      this.delSection(ind);
    }
  };

  closeMoveSection = (val) => {
    const { slot_id } = this.state;

    if (val !== "" && val > 0) {
      const post_all = [
        { id: slot_id, entity_type: "slot", section: { id: val } },
      ];
      this.updateSchedule(post_all);
    }

    this.setState({ sect_id: 0, slot_id: 0, moveDialogOpen: false });
  };

  moveSlotToSection = (sectIndex, id) => {
    const { sections } = this.props.schedule;

    let sect_id = 0;

    if (sectIndex >= 0) {
      sect_id = sections[sectIndex].id;
    }

    this.setState({ sect_id, slot_id: id, moveDialogOpen: true });
  };

  moveSlotUp = (sectIndex, slotIndex) => {
    const { sections, slots } = this.props.schedule;

    if (sectIndex > -1) {
      const sectItem = sections[sectIndex].slots;

      console.log(sectItem);

      if (slotIndex > 0) {
        const tmpElem = { ...sectItem[slotIndex] };
        sectItem.splice(slotIndex, 1);
        sectItem.splice(slotIndex - 1, 0, tmpElem);
        const slotCount = sectItem.length;

        this.moveSlotOrder(
          sectItem.map(function (item, index) {
            return {
              id: item.id,
              order: slotCount - index,
              entity_type: "slot",
            };
          })
        );
      }
    } else {
      const tmpSlots = [...slots];
      const tmpElem = { ...slots[slotIndex] };
      tmpSlots.splice(slotIndex, 1);
      tmpSlots.splice(slotIndex - 1, 0, tmpElem);
      const slotCount = slots.length;

      this.moveSlotOrder(
        tmpSlots.map(function (item, index) {
          return { id: item.id, order: slotCount - index, entity_type: "slot" };
        })
      );
    }
  };

  moveSlotDown = (sectIndex, slotIndex) => {
    const { sections, slots } = this.props.schedule;

    if (sectIndex > -1) {
      const sectItem = sections[sectIndex].slots;

      console.log(sectItem);

      if (slotIndex < sectItem.length - 1) {
        const tmpElem = { ...sectItem[slotIndex] };
        sectItem.splice(slotIndex, 1);
        sectItem.splice(slotIndex + 1, 0, tmpElem);
        const slotCount = sectItem.length;

        this.moveSlotOrder(
          sectItem.map(function (item, index) {
            return {
              id: item.id,
              order: slotCount - index,
              entity_type: "slot",
            };
          })
        );
      }
    } else {
      if (slotIndex == slots.length - 1) {
        return;
      }

      const tmpSlots = [...slots];
      const tmpElem = { ...slots[slotIndex] };
      tmpSlots.splice(slotIndex, 1);
      tmpSlots.splice(slotIndex + 1, 0, tmpElem);
      const slotCount = slots.length;

      this.moveSlotOrder(
        tmpSlots.map(function (item, index) {
          return { id: item.id, order: slotCount - index, entity_type: "slot" };
        })
      );
    }
  };

  addSection = () => {
    this.setState({
      sectionDialogOpen: true,
      sectionInd: -1,
      sectionName: "",
      sectionNameI18N: {},
      sectionDesc: tmpDesc,
      sectionDescI18N: tmpDescI18N,
    });
  };

  addSlot = (sectIndex = null) => {
    // this.setState({sectionDialogOpen: true,sectionInd:-1,sectionName:''});

    const { selectedDate, format } = this.state;
    const post_data = [{ name: "", date: selectedDate, entity_type: "slot" }];

    const { sections } = this.props.schedule;

    if (sectIndex >= 0) {
      const sectId = sections[sectIndex].id;
      post_data[0].section = { id: sectId };
    }
    //post_data[0].type = format;

    this.updateSchedule(post_data, true);
  };

  delSection = (ind) => {
    scheduleService.delSection(this.props.conf_id, ind);
  };

  setBookable = (e, id1, sectIndex, slotIndex) => {
    let isbook = 0;

    let self = this;

    console.log(e);

    let slot = null;
    const { sections, slots, speakers } = this.props.schedule;

    let id = -1;

    if (sectIndex < 0) slot = slots[slotIndex];
    else slot = sections[sectIndex].slots[slotIndex];

    if (slot.entity) id = slot.entity.id;
    else id = slot.id;

    if (e.target.checked == true) {
      isbook = 1;
    }

    if (id < 0) return;

    userService.patchWrapper(
      "api/lesson/" + id + "?bookable=" + isbook,
      function (data) {
        if (data.status == 200) {
          self.getSchedule(self.props.conf_id);
        } else {
          alert(data.status);
        }
      },
      function (error) {
        alert(error);
      }
    );
  };

  changeMode = (e, item, sectIndex, slotIndex) => {
    if (item.slot_type === undefined)
      scheduleService.setSlotMode(
        this.props.conf_id,
        sectIndex,
        slotIndex,
        this.state.format
      );
    else if (item.slot_type == "lesson" || item.slot_type == "service_lesson") {
      if (this.state.format == "hybrid") {
        let newFormat = "hybrid";

        if (e == "online") newFormat = "offline";

        if (e == "hybrid") newFormat = "online";

        let post_data = [
          {
            id: item.id,
            entity_type: "slot",
            slot_entity: {
              entity_type: item.slot_type,
              entity_id: item.entity.id,
              type: newFormat,
            },
          },
        ];

        this.updateSchedule(post_data);
      }
    }
  };

  changeSpeakers = (e, sectIndex, slotIndex, spIndex) => {
    const { speakersCounter } = this.state;
    const { sections, slots, speakers } = this.props.schedule;
    let isNew = true;
    let newId = -1;

    if (e) {
      for (let i = 0; i < speakers.length; i++) {
        if (speakers[i].name == e) {
          isNew = false;
          newId = speakers[i].id;
        }
      }

      let oldSp = null;

      if (sectIndex >= 0) {
        if (
          sections[sectIndex].slots[slotIndex].entity &&
          sections[sectIndex].slots[slotIndex].entity.speakers
        )
          oldSp = {
            ...sections[sectIndex].slots[slotIndex].entity.speakers[spIndex],
          };
      } else {
        if (slots[slotIndex].entity && slots[slotIndex].entity.speakers)
          oldSp = { ...slots[slotIndex].entity.speakers[spIndex] };
      }
      // sections[sectIndex].slots[slotIndex].speakers[spIndex] = {...oldSp,id:newId,name:e};

      let oldId = 0;
      if (oldSp && oldSp.id) {
        oldId = oldSp.id;
      }

      let lessonId = null;
      let entity_id = null;

      if (sectIndex >= 0) {
        lessonId = sections[sectIndex].slots[slotIndex].id;
        entity_id = sections[sectIndex].slots[slotIndex].entity.id;
      } else {
        lessonId = slots[slotIndex].id;

        entity_id = slots[slotIndex].entity.id;
      }

      const post_data = [
        {
          id: lessonId,
          entity_type: "slot",
          //slot_entity: { entity_type: "lesson", entity_id },
          speakers: [],
        },
      ];

      if (oldId > 0) {
        post_data[0].speakers.push({ action: "unlink", id: oldId });
      }

      if (newId < 1) {
        post_data[0].speakers.push({ name: e });
        this.updateSchedule(post_data);

        // this.setState({speakers:speakers,speakersCounter:newId});
      } else {
        post_data[0].speakers.push({ id: newId });
        this.updateSchedule(post_data);

        this.setState({ speakers });
      }
    } else {
      let oldSp = null;

      if (sectIndex >= 0) {
        if (
          sections[sectIndex].slots[slotIndex].entity &&
          sections[sectIndex].slots[slotIndex].entity.speakers
        )
          oldSp = {
            ...sections[sectIndex].slots[slotIndex].entity.speakers[spIndex],
          };
      } else {
        if (slots[slotIndex].entity && slots[slotIndex].entity.speakers)
          oldSp = { ...slots[slotIndex].entity.speakers[spIndex] };
      }

      // sections[sectIndex].slots[slotIndex].speakers[spIndex] = {...oldSp,name:''}
      let oldId = 0;
      if (oldSp && oldSp.id) {
        oldId = oldSp.id;
      }

      if (oldId > 0) {
        let lessonId = null;
        let entityId = null;

        if (sectIndex >= 0) {
          lessonId = sections[sectIndex].slots[slotIndex].id;
          entityId = sections[sectIndex].slots[slotIndex].entity.id;
        } else {
          lessonId = slots[slotIndex].id;
          entityId = slots[slotIndex].entity.id;
        }

        const post_data = [
          {
            id: lessonId,
            entity_type: "slot",
            //slot_entity: { entity_type: "lesson", entity_id: entityId },
            speakers: [{ id: oldId, action: "unlink" }],
          },
        ];

        this.updateSchedule(post_data);
      }
    }
  };

  closeModerSection = (data) => {
    console.log(data);
    const c_id = this.props.conf_id;
    const { sections } = this.props.schedule;
    const { sectionInd } = this.state;

    if (data) {
      let sect_id = sections[sectionInd].id;
      let modIds = data.filter((e) => e.name != "").map((e) => e.id);
      scheduleService.changeSectionModers(c_id, sect_id, modIds);
    }

    this.setState({
      moderatorsDialogOpen: false,
      sectionInd: -1,
      moderators: [],
    });
  };

  closeEditSection = (e) => {
    const { sectionInd } = this.state;

    this.setState({
      sectionDialogOpen: false,
      sectionInd: -1,
      sectionName: "",
      sectionNameI18N: {},
      sectionDesc: tmpDesc,
      sectionDescI18N: tmpDescI18N,
    });
    if (e && e.name) {
      this.changeSectionName(e, sectionInd);
    }
  };

  editSectionName = (index) => {
    const { sections } = this.props.schedule;

    const { name, name_i18n, description, description_i18n } = sections[index];

    console.log("S", sections[index]);

    console.log("E", description_i18n);

    this.setState({
      sectionDialogOpen: true,
      sectionInd: index,
      sectionName: name,
      sectionNameI18N: name_i18n ?? {},
      sectionDesc: description ?? tmpDesc,
      sectionDescI18N: description_i18n ?? tmpDescI18N,
    });

    console.log("SE", this.state.sectionDescI18N);
  };

  formatServerTime = (tS, tE) => {
    if (tS && tE) {
      const tS_arr = tS.split(" ");
      const time_start_arr = tS_arr[1].split(":");

      const time1 = new Date(tS);
      const time2 = new Date(tE);
      const duration = Math.floor((time2.getTime() - time1.getTime()) / 60000);

      return { time: `${time_start_arr[0]}:${time_start_arr[1]}`, duration };
    }

    return { time: null, duration: null };
  };

  formatTime = (timeSrc) => {
    const resStr = `${timeSrc.getFullYear()}-${
      1 + timeSrc.getMonth()
    }-${timeSrc.getDate()} ${timeSrc.getHours()}:${timeSrc.getMinutes()}`;

    return resStr;
  };

  saveUrl = (item, url, itemSect) => {
    const { allLessons } = this.props.schedule;

    let curTheme = item.entity;

    if (!item.entity) curTheme = { ...item };

    let { id } = curTheme;

    if (curTheme) {
      if (
        curTheme.url_online != url &&
        !(!curTheme.url_online && url.length == 0)
      ) {
        if (confService.validateUrl(url) || !url)
          scheduleService.saveUrl(this.props.conf_id, id, url ? url : null);
        else {
          this.props.dispatch(
            userActions.set_error(i18next.t("incorrect_url"), "warning")
          );
          this.getSchedule(this.props.conf_id);
        }
      }
    }
  };

  plusDuration = (sectIndex, index) => {
    let duration = 0;
    const { sections, slots } = this.props.schedule;
    let id = -1;
    let post_all = {};

    if (sectIndex >= 0) {
      duration = sections[sectIndex].slots[index].duration;
      id = sections[sectIndex].slots[index].id;
    } else {
      duration = slots[index].duration;
      id = slots[index].id;
    }

    duration += 5;

    if (duration < 1) duration = 1;
    post_all.duration = duration;

    if (id > 0) post_all.id = id;
    post_all["entity_type"] = "slot";

    post_all = [{ ...post_all }];

    this.updateSchedule(post_all);
  };

  minusDuration = (sectIndex, index) => {
    let duration = 0;
    const { sections, slots } = this.props.schedule;
    let id = -1;
    let post_all = {};

    if (sectIndex >= 0) {
      duration = sections[sectIndex].slots[index].duration;
      id = sections[sectIndex].slots[index].id;
    } else {
      duration = slots[index].duration;
      id = slots[index].id;
    }

    duration -= 5;

    if (duration < 1) duration = 1;
    post_all.duration = duration;

    if (id > 0) post_all.id = id;

    post_all = [{ ...post_all }];
    this.updateSchedule(post_all);
  };

  saveTime = (item, time, duration, itemSect) => {
    let { id } = item;
    let isUpdate = false;
    let sType = null;
    const { allLessons } = this.props.schedule;

    if ((item && item.start_time != time) || !item.start_time) {
      isUpdate = true;
    }

    sType = item["slot_type"];

    const { selectedDate } = this.state;
    const self = this;

    if (time || duration) {
      let post_all = {};

      if (time) {
        const time_arr = time.split(":");
        if (time_arr.length > 1) {
          post_all.start_time = `${time_arr[0]}:${time_arr[1]}`;
        }
      }

      const dateStart = new Date(selectedDate);

      post_all.date = selectedDate;

      if (sType || !item.theme) post_all.entity_type = "slot";

      if (duration < 1) duration = 1;

      if (duration && duration > 0) {
        post_all.duration = duration;
      }

      if (id) post_all.id = id;

      post_all = [{ ...post_all }];

      document.activeElement.blur();

      this.updateSchedule(post_all, isUpdate);
    }
  };

  changeThemes = (newVal, oldVal, index, slotIndex) => {
    const { sections, slots, themes } = this.props.schedule;
    const { format } = this.state;
    let id = 0;
    let oldObj = {};

    let isEmptyDelete = false;

    if (index >= 0) {
      oldObj = { ...sections[index].slots[slotIndex] };
    } else {
      oldObj = { ...slots[slotIndex] };
    }

    id = oldObj.id;

    if (!newVal) {
      let post_data = [];

      post_data = [
        {
          id: oldObj.id,
          entity_type: "slot",
          slot_entity: { entity_type: null },
        },
      ];

      this.updateSchedule(post_data);
      return;
    }

    let post_data = [];

    const fTheme = themes.find((e1) => e1.name == newVal);

    if (fTheme) {
      post_data = [
        {
          id: oldObj.id,
          entity_type: "slot",
          slot_entity: {
            entity_type: "lesson",
            entity_id: fTheme.id,
            name: fTheme.name,
            type: this.state.format,
          },
        },
      ];

      this.updateSchedule(post_data);
    } else {
      post_data = [
        {
          id: oldObj.id,
          entity_type: "slot",
          slot_entity: {
            entity_type: "lesson",
            name: newVal,
            type: this.state.format,
          },
        },
      ];
      this.updateSchedule(post_data);
    }
  };

  getDayOfWeek = (datestr) => {
    const dow_data = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];

    let date = new Date(datestr);

    return dow_data[date.getDay()];
  };

  onSaveFake = () => {
    const { classes, dispatch } = this.props;

    dispatch(userActions.set_error(i18next.t("changes_saved"), "info"));
  };

  changeRoom = (e, item, index, slotIndex) => {
    const { roomsCounter } = this.state;
    const { sections, slots, rooms } = this.props.schedule;

    const new_sections = [...sections];

    let roomId = roomsCounter - 1;

    if (e) {
      for (let i = 0; i < rooms.length; i++) {
        if (rooms[i].number == e) {
          roomId = rooms[i].id;
        }
      }

      if (roomId < 0) {
        rooms.push({ id: roomId, number: e });
      }

      let lessonId = null;

      if (index >= 0) {
        lessonId = sections[index].slots[slotIndex].id;
      } else {
        lessonId = slots[slotIndex].id;
      }

      const post_data = [{ id: lessonId, room: {} }];
      if (roomId > 0) {
        post_data[0].room.id = roomId;
      } else {
        post_data[0].room.number = e;
      }

      if (item.slot_type !== undefined) post_data[0].entity_type = "slot";

      this.updateSchedule(post_data);
    } else {
      let isUnlink = false;

      const post_data = [{ room: { action: "unlink", id: item.room_id } }];

      if (
        index >= 0 &&
        new_sections[index] &&
        new_sections[index].slots[slotIndex] &&
        new_sections[index].slots[slotIndex].room
      ) {
        const lessonId = sections[index].slots[slotIndex].id;
        post_data[0].id = lessonId;
        isUnlink = true;
      }

      if (index < 0 && slots[slotIndex] && slots[slotIndex].room) {
        const lessonId = slots[slotIndex].id;
        post_data[0].id = lessonId;
        isUnlink = true;
      }

      if (isUnlink) {
        if (item.slot_type !== undefined) post_data[0].entity_type = "slot";
        this.updateSchedule(post_data);
      }
    }
  };

  render() {
    const { classes } = this.props;
    const {
      selectedDate,
      aroundDates,
      minDate,
      maxDate,
      isLoading,
      dragMenuOpen,
      fileStatus,
      showDeclined,
      isDragging,
      addType,
      addDialog,
      editDialog,
      editType,
      editVal,
      sect_id,
      moveDialogOpen,
      format,
      isSlotService,
    } = this.state;

    const { sections, slots, rooms, themes, service_themes, speakers } =
      this.props.schedule;

    const self = this;

    if (this.props.conf_id > 0) {
      return (
        <DragDropContext
          onDragEnd={this.handleStop}
          onDragStart={this.handleStart}
        >
          <div
            className={
              dragMenuOpen ? "contstructor-page open-tabs" : "contstructor-page"
            }
          >
            <section className="constructor-row">
              <div className="constructor-table">
                <Breadcrumbs
                  separator="›"
                  aria-label="breadcrumb"
                  style={{ marginTop: 5 }}
                >
                  <Link underline="always" color="inherit" href="/">
                    {i18next.t("confs_title")}
                  </Link>
                  <Link
                    underline="always"
                    color="text.primary"
                    href={"/conf_param/" + this.props.conf_data.id}
                  >
                    {this.props.conf_data.name}
                  </Link>
                  <h4 color="text.primary">{i18next.t("conf_prog")}</h4>
                </Breadcrumbs>
                <div className="head-page">
                  <div className="head-title-row" style={{ minWidth: 250 }}>
                    <div
                      onClick={() =>
                        this.setState({ showSettingsDialog: true })
                      }
                    >
                      <img
                        src={settingsImg}
                        style={{ marginLeft: 15 }}
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="tab-switcher"
                    style={{ marginBottom: 10, marginTop: 10 }}
                  >
                    <ul className="tabs-member">
                      <button className="filter-arrows">
                        <LeftIcon onClick={() => self.dateShift(-1)} />
                      </button>
                      {aroundDates.map((item) => (
                        <li
                          className={item === selectedDate ? "active" : ""}
                          onClick={() => self.clickDay(item)}
                        >
                          {self.getDayOfWeek(item)}{" "}
                          {item.split("-").reverse().join(".")}
                        </li>
                      ))}
                      <button className="filter-arrows">
                        <RightIcon onClick={() => self.dateShift(1)} />
                      </button>
                    </ul>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      onClick={self.addSection}
                      style={{ marginRight: 20, cursor: "pointer" }}
                    >
                      <img
                        src={addLogo}
                        style={{ marginLeft: 5, marginBottom: 5 }}
                        alt=""
                      />
                      <span className="link-name">
                        {i18next.t("add_section")}
                      </span>
                    </div>
                    <div
                      onClick={self.addSlot}
                      style={{ marginRight: 30, cursor: "pointer" }}
                    >
                      <img
                        src={addLogo}
                        style={{ marginLeft: 5, marginBottom: 5 }}
                        alt=""
                      />
                      <span className="link-name">{i18next.t("add_slot")}</span>
                    </div>
                    <button
                      className="block-dropdwon__btn"
                      onClick={this.onSaveFake}
                    >
                      {i18next.t("btn_save")}
                    </button>
                  </div>
                </div>
                <div className="table">
                  <div className="head-contstructor">
                    <div className="time">
                      <div className="col-name">{i18next.t("time")}</div>
                    </div>
                    <div className="duration">
                      <div className="col-name">{i18next.t("duration")}</div>
                    </div>
                    <div className="theme">
                      <div className="col-name">{i18next.t("thema")}</div>
                    </div>
                    <div className="speaker">
                      <div className="col-name">{i18next.t("speaker")}</div>
                    </div>
                    <div className="room">
                      <div className="col-name">{i18next.t("room")}</div>
                    </div>
                  </div>
                  <div
                    className="body-contstructor custom-scroll"
                    data-simplebar="init"
                  >
                    {slots.length > 0 &&
                      slots.map((item, slotIndex) => (
                        <SlotItem
                          item={item}
                          mainIndex={-1}
                          showDeclined={showDeclined}
                          index={slotIndex}
                          addSpeaker={self.addSpeaker}
                          delSpeaker={self.delSpeaker}
                          speakers={speakers.map((e) => e.name)}
                          rooms={rooms.map((e) => e.number)}
                          themes={themes
                            .filter((e) => !e.date)
                            .map((e) => e.name)}
                          changeMode={(e) =>
                            self.changeMode(e, item, -1, slotIndex)
                          }
                          changeThemes={(e, eOld) =>
                            self.changeThemes(e, eOld, -1, slotIndex)
                          }
                          changeRoom={(a, b) =>
                            self.changeRoom(a, b, -1, slotIndex)
                          }
                          changeSpeakers={(e, i) =>
                            self.changeSpeakers(e, -1, slotIndex, i)
                          }
                          changeSpeakerPartip={(e, i) =>
                            self.changeSpeakerPartip(e, -1, slotIndex, i)
                          }
                          changeSpeakerContract={(e, i) =>
                            self.changeSpeakerContract(e, -1, slotIndex, i)
                          }
                          setBookable={(e, i) =>
                            self.setBookable(e, i, -1, slotIndex)
                          }
                          saveTime={self.saveTime}
                          saveUrl={self.saveUrl}
                          plusDuration={self.plusDuration}
                          minusDuration={self.minusDuration}
                          moveSlot={self.moveSlotToSection}
                          moveUp={self.moveSlotUp}
                          moveDown={self.moveSlotDown}
                          delSlot={() => self.delSlot(-1, slotIndex)}
                          editTheme={self.editThemeFromList}
                          format={self.state.format}
                        />
                      ))}

                    {sections.length > 0 &&
                      sections.map((itemSect, indexSect) => (
                        <SectionItem
                          showDeclined={showDeclined}
                          slot={itemSect}
                          id={itemSect.id}
                          indexSect={indexSect}
                          themes={themes
                            .filter((e) => !e.date)
                            .map((e) => e.name)}
                          speakers={speakers.map((e) => e.name)}
                          rooms={rooms.map((e) => e.number)}
                          editSectionName={() =>
                            self.editSectionName(indexSect)
                          }
                          plusDuration={self.plusDuration}
                          minusDuration={self.minusDuration}
                          saveTime={(a, b, c) =>
                            self.saveTime(a, b, c, itemSect)
                          }
                          saveUrl={(a, b) => self.saveUrl(a, b, itemSect)}
                          sectionNameChange={(e) =>
                            self.changeSectionName(e, indexSect)
                          }
                          sectionOnDelete={() =>
                            self.deleteSectionDialog(indexSect)
                          }
                          setBookable={self.setBookable}
                          changeThemes={self.changeThemes}
                          changeMode={self.changeMode}
                          changeSpeakers={self.changeSpeakers}
                          changeSpeakerPartip={self.changeSpeakerPartip}
                          changeRoom={self.changeRoom}
                          addTheme={self.addSlot}
                          addSpeaker={self.addSpeaker}
                          delSpeaker={self.delSpeaker}
                          delSlot={self.delSlot}
                          delTheme={self.delThemeFromList}
                          unbindSlot={self.unbindSlot}
                          moveSlot={self.moveSlotToSection}
                          moveSlotUp={self.moveSlotUp}
                          moveSlotDown={self.moveSlotDown}
                          sectUp={self.sectUp}
                          sectDown={self.sectDown}
                          setSortType={self.setSortType}
                          editTheme={self.editThemeFromList}
                          editModerators={self.editModerators}
                          format={self.state.format}
                        />
                      ))}
                    <SlotPlaceholder
                      onAddSection={self.addSection}
                      onAddSlot={self.addSlot}
                      text_add_slot={i18next.t("add_slot")}
                      text_add_section={i18next.t("add_section")}
                    />
                  </div>
                </div>
              </div>
              <DragMenu
                speakers={speakers}
                rooms={rooms}
                themes={themes}
                service_themes={service_themes}
                isOpen={dragMenuOpen}
                onDragClick={() => self.dragMenuClick()}
                addThemeDialog={self.addThemeDialog}
                addSpeakerDialog={self.addSpeakerDialog}
                addRoomDialog={self.addRoomDialog}
                editTheme={self.editThemeFromList}
                delTheme={self.delThemeFromList}
                editServiceTheme={self.openEditServiceTheme}
                delServiceTheme={self.delServiceTheme}
                editSpeaker={self.editSpeakerFromList}
                delSpeaker={self.delSpeakerFromList}
                editRoom={self.editRoomFromList}
                delRoom={self.delRoomFromList}
                format={format}
              />
            </section>
          </div>

          <SectionDialog
            handleClose={self.closeEditSection}
            dName={this.state.sectionName}
            dNameI18N={this.state.sectionNameI18N}
            dDesc={this.state.sectionDesc}
            dDescI18N={this.state.sectionDescI18N}
            dialogOpen={this.state.sectionDialogOpen}
            text_cancel={i18next.t("btn_cancel")}
            text_save={i18next.t("btn_save")}
            text_section_name={i18next.t("section_name")}
            placeholder_texts={{
              description: i18next.t("dialog_desc"),
              room: i18next.t("room"),
              time: i18next.t("time"),
              url: i18next.t("url_translation"),
            }}
          />
          <ModeratorsDialog
            handleClose={self.closeModerSection}
            dialogOpen={this.state.moderatorsDialogOpen}
            text_cancel={i18next.t("btn_cancel")}
            text_save={i18next.t("btn_save")}
            text_section_name={i18next.t("section_name")}
            text_moderator={i18next.t("moderator")}
            text_add={i18next.t("btn_add")}
            mainIndex={this.state.sectionInd}
            speakers={this.state.moderators}
            allSpeakers={speakers}
          />
          <MoveDialog
            handleClose={self.closeMoveSection}
            sect_id={sect_id}
            sect_list={sections}
            dialogOpen={moveDialogOpen}
            text_cancel={i18next.t("btn_cancel")}
            text_save={i18next.t("btn_save")}
            text_select_session={i18next.t("select_section")}
          />
          <AddDialog
            nameLabel={this.state.dialogLabel}
            handleClose={self.closeDialog}
            label={addType ? i18next.t(add_textes[addType]) : ""}
            title={addType ? i18next.t(add_textes[addType]) : ""}
            onFileUpload={self.fileUpload}
            fileStatus={fileStatus}
            dialogType={addType}
            dialogOpen={addDialog}
            text_cancel={i18next.t("btn_cancel")}
            text_save={i18next.t("btn_save")}
          />

          <EditDialog
            handleClose={self.closeEditDialog}
            label={editType ? i18next.t(edit_textes[editType]) : ""}
            title={editType ? i18next.t(edit_textes[editType]) : ""}
            oldVal={editVal}
            onFileUpload={self.fileUpload}
            isSlotService={isSlotService}
            fileStatus={fileStatus}
            dialogType={editType}
            dialogOpen={editDialog}
          />
          <SettingsDialog
            handleClose={self.closeSettingsDialog}
            showDecl={showDeclined}
            dialogOpen={this.state.showSettingsDialog}
            text_settings={i18next.t("settings")}
            text_show_declined_speakers={i18next.t("show_declined_speakers")}
            text_save={i18next.t("btn_save")}
            text_cancel={i18next.t("btn_cancel")}
          />

          {isLoading && (
            <div className="shadow">
              <div className="shadow_circle">
                <CircularProgress />
              </div>
            </div>
          )}
        </DragDropContext>
      );
    }
    return <ConferenceNotSelected page={"conf_prog"} />;
  }
}

function mapStateToProps(state) {
  const { app, user, authentication, schedule } = state;

  return {
    user,
    app,
    authentication,
    schedule,
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(TimeTable)
);
